import mock from '../mock';

// ** Utils
import { paginateArray } from '../utils';

const data = {
  users: [
    {
      id: 1,
      billing: 'Manual - Credit Card',
      fullName: 'Galen Slixby',
      company: 'Yotz PVT LTD',
      role: 'editor',
      username: 'gslixby0',
      country: 'Santa Marta',
      contact: '(479) 232-9151',
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      status: 'inactivo',
      avatar: '',
      avatarColor: 'light-primary',
      followers: '1.234',
      posicion: 'Gobernación',
      title: 'Programa para manejo ambiental',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      status1: 'Aprobado'
    },
    {
      id: 3,
      billing: 'Auto Debit',
      fullName: 'Marjory Sicely',
      company: 'Oozz PVT LTD',
      role: 'maintainer',
      username: 'msicely2',
      country: 'Riohacha',
      contact: '(321) 264-4599',
      email: 'msicely2@who.int',
      currentPlan: 'enterprise',
      status: 'activo',
      avatar: require('@src/assets/images/avatars/1.png').default,
      followers: '124',
      posicion: 'Gobernación',
      title: 'Impuestos para las iglesias',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      status1: 'Aprobado'
    },
    {
      id: 4,
      billing: 'Manual - Credit Card',
      fullName: 'Cyrill Risby',
      company: 'Oozz PVT LTD',
      role: 'maintainer',
      username: 'crisby3',
      country: 'Neiva',
      contact: '(923) 690-6806',
      email: 'crisby3@wordpress.com',
      currentPlan: 'team',
      status: 'inactivo',
      avatar: require('@src/assets/images/avatars/9.png').default,
      followers: '1.234',
      posicion: 'Gobernación',
      title: 'Plan para cultivar el arte en los jóvenes',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      status1: 'Pendiente'
    },
    {
      id: 6,
      billing: 'Auto Debit',
      fullName: 'Silvain Halstead',
      company: 'Jaxbean PVT LTD',
      role: 'author',
      username: 'shalstead5',
      country: 'Inírida',
      contact: '(958) 973-3093',
      email: 'shalstead5@shinystat.com',
      currentPlan: 'company',
      status: 'activo',
      avatar: '',
      avatarColor: 'light-success',
      followers: '13',
      posicion: 'Alcaldía',
      title: 'Viabilidad Metro Centro Poblado',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      status1: 'Aprobado'
    },
    {
      id: 10,
      billing: 'Auto Debit',
      fullName: 'Jillene Bellany',
      company: 'Gigashots PVT LTD',
      role: 'maintainer',
      username: 'jbellany9',
      country: 'Montería',
      contact: '(589) 284-6732',
      email: 'jbellany9@kickstarter.com',
      currentPlan: 'company',
      status: 'inactivo',
      avatar: require('@src/assets/images/avatars/9.png').default,
      followers: '23',
      posicion: 'Congreso',
      title: 'Impuestos para las iglesias',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      status1: 'Pendiente'
    },
    {
      id: 11,
      billing: 'Manual - Paypal',
      fullName: 'Jonah Wharlton',
      company: 'Eare PVT LTD',
      role: 'subscriber',
      username: 'jwharltona',
      country: 'Quibdó',
      contact: '(176) 532-6824',
      email: 'jwharltona@oakley.com',
      currentPlan: 'team',
      status: 'inactivo',
      avatar: require('@src/assets/images/avatars/4.png').default,
      followers: '654',
      posicion: 'Congreso',
      title: 'Programa para manejo ambiental',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      status1: 'Pendiente'
    },
    {
      id: 13,
      billing: 'Auto Debit',
      fullName: 'Yoko Pottie',
      company: 'Leenti PVT LTD',
      role: 'subscriber',
      username: 'ypottiec',
      country: 'Valledupar',
      contact: '(907) 284-5083',
      email: 'ypottiec@privacy.gov.au',
      currentPlan: 'basic',
      status: 'inactivo',
      avatar: require('@src/assets/images/avatars/7.png').default,
      followers: '333',
      posicion: 'Asamblea',
      title: 'Plan para cultivar el arte en los jóvenes',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      status1: 'Pendiente'
    },
    {
      id: 14,
      billing: 'Auto Debit',
      fullName: 'Maximilianus Krause',
      company: 'Digitube PVT LTD',
      role: 'author',
      username: 'mkraused',
      country: 'Popayán',
      contact: '(167) 135-7392',
      email: 'mkraused@stanford.edu',
      currentPlan: 'team',
      status: 'activo',
      avatar: require('@src/assets/images/avatars/9.png').default,
      followers: '432',
      posicion: 'Asamblea',
      title: 'Programa para el adulto mayor y jubilación',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      type: 'Presencial',
      status1: 'Pendiente'
    },
    {
      id: 15,
      billing: 'Auto Debit',
      fullName: 'Zsazsa McCleverty',
      company: 'Kaymbo PVT LTD',
      role: 'maintainer',
      username: 'zmcclevertye',
      country: 'Yopal',
      contact: '(317) 409-6565',
      email: 'zmcclevertye@soundcloud.com',
      currentPlan: 'enterprise',
      status: 'activo',
      avatar: require('@src/assets/images/avatars/2.png').default,
      followers: '14',
      posicion: 'Alcaldía',
      title: 'Viabilidad Metro Centro Poblado',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      type: 'Presencial',
      status1: 'Aprobado'
    },
    {
      id: 16,
      billing: 'Auto Debit',
      fullName: 'Bentlee Emblin',
      company: 'Yambee PVT LTD',
      role: 'author',
      username: 'bemblinf',
      country: 'Florencia',
      contact: '(590) 606-1056',
      email: 'bemblinf@wired.com',
      currentPlan: 'company',
      status: 'activo',
      avatar: require('@src/assets/images/avatars/6.png').default,
      followers: '24',
      posicion: 'Alcaldía',
      title: 'Impuestos para las iglesias',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      type: 'Virtual',
      status1: 'Aprobado',
      nameEvent: 'Programa para manejo ambiental',

    },
    {
      id: 17,
      billing: 'Manual - Paypal',
      fullName: 'Brockie Myles',
      company: 'Wikivu PVT LTD',
      role: 'maintainer',
      username: 'bmylesg',
      country: 'Manizales',
      contact: '(553) 225-9905',
      email: 'bmylesg@amazon.com',
      currentPlan: 'basic',
      status: 'activo',
      avatar: '',
      avatarColor: 'light-warning',
      followers: '123',
      posicion: 'Congreso',
      title: 'Programa para manejo ambiental',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      type: 'Virtual',
      status1: 'Aprobado',
      nameEvent: 'Plan para cultivar el arte en los jóvenes',

    },
    {
      id: 18,
      billing: 'Manual - Cash',
      fullName: 'Bertha Biner',
      company: 'Twinte PVT LTD',
      role: 'editor',
      username: 'bbinerh',
      country: 'Tunja',
      contact: '(901) 916-9287',
      email: 'bbinerh@mozilla.com',
      currentPlan: 'team',
      status: 'activo',
      avatar: require('@src/assets/images/avatars/7.png').default,
      followers: '144',
      posicion: 'Gobernación',
      title: 'Plan para cultivar el arte en los jóvenes',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      type: 'Virtual',
      status1: 'Aprobado',
      nameEvent: 'Programa para el adulto mayor y jubilación',

    },
    {
      id: 19,
      billing: 'Manual - Cash',
      fullName: 'Travus Bruntjen',
      company: 'Cogidoo PVT LTD',
      role: 'admin',
      username: 'tbruntjeni',
      country: 'Barranquilla',
      contact: '(524) 586-6057',
      email: 'tbruntjeni@sitemeter.com',
      currentPlan: 'enterprise',
      status: 'activo',
      avatar: '',
      avatarColor: 'light-info',
      followers: '34',
      posicion: 'Alcaldía',
      title: 'Programa para el adulto mayor y jubilación',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      type: 'Presencial',
      status1: 'Aprobado',
      nameEvent: 'Viabilidad Metro Centro Poblado',
    },
    {
      id: 20,
      billing: 'Auto Debit',
      fullName: 'Wesley Burland',
      company: 'Bubblemix PVT LTD',
      role: 'editor',
      username: 'wburlandj',
      country: 'Armenia',
      contact: '(569) 683-1292',
      email: 'wburlandj@uiuc.edu',
      currentPlan: 'team',
      status: 'inactivo',
      avatar: require('@src/assets/images/avatars/6.png').default,
      followers: '234',
      posicion: 'Asamblea',
      title: 'Viabilidad Metro Centro Poblado',
      data: '12 de enero, 2021 · 14:32',
      refused: 'inactivo',
      type: 'Presencial',
      nameEvent: '¿Cuál es la edad ideal para pensionarse en Colombia?',
      img: require('@src/assets/images/avatars/Grupo1.png').default,
      tipeEvents: 'Candidato',
      status1: 'Aprobado'
    },
    {
      id: 21,
      billing: 'Manual - Cash',
      fullName: 'Winder Principal',
      company: 'Cogidoo PVT LTD',
      role: 'admin',
      username: 'tbruntjeni',
      country: 'Arauca',
      contact: '(524) 586-6057',
      email: 'winder@wjpv.com',
      currentPlan: 'enterprise',
      status: 'activo',
      avatar: '',
      avatarColor: 'light-danger',
      followers: '1.234',
      posicion: 'Congreso',
      title: 'Impuestos para las iglesias',
      data: '12 de enero, 2021 · 14:32',
      refused: 'rechazado',
      type: 'Virtual',
      nameEvent: '¿Cuál es la edad ideal para pensionarse en Colombia?',
      tipeEvents: 'Pregunta',
      status1: 'Pendiente'
    }
  ]
};

// GET ALL DATA
mock.onGet('/api/users/list/all-data').reply(200, data.users);

// POST: Add new user
mock.onPost('/apps/users/add-user').reply((config) => {
  // Get event from post data
  const user = JSON.parse(config.data);
  const highestValue = data.users.reduce((a, b) => (a.id > b.id ? a : b)).id;

  user.id = highestValue + 1;

  data.users.push(user);

  return [201, { user }];
});

// GET Updated DATA
mock.onGet('/api/users/list/data').reply((config) => {
  const {
    q = '',
    page = 1,
    role = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    currentPlan = null,
    sortColumn = 'fullName'
  } = config;

  /* eslint-disable  */
  const queryLowered = q.toLowerCase();

  const dataAsc = data.users.sort((a, b) =>
    a[sortColumn] < b[sortColumn] ? -1 : 1
  );

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse();

  const filteredData = dataToFilter.filter(
    (user) =>
      (user.email.toLowerCase().includes(queryLowered) ||
        user.fullName.toLowerCase().includes(queryLowered) ||
        user.billing.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (currentPlan || user.currentPlan) &&
      user.status === (status || user.status)
  );
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      users: paginateArray(filteredData, perPage, page)
    }
  ];
});

// GET USER
mock.onGet('/api/users/user').reply((config) => {
  const { id } = config;
  const user = data.users.find((i) => i.id === id);
  return [200, { user }];
});

// DELETE: Deletes User
mock.onDelete('/apps/users/delete').reply((config) => {
  // Get user id from URL
  let userId = config.id;

  // Convert Id to number
  userId = Number(userId);

  const userIndex = data.users.findIndex((t) => t.id === userId);
  data.users.splice(userIndex, 1);

  return [200];
});
